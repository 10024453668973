@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Bebas Neue', cursive;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
}

html {
  background-color: rgb(255, 255, 255);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h2{
  cursor: default;
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
  color: black;
  font-family: 'Bebas Neue';
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

h2 > span{
  position: relative;
  display: block;
  overflow: hidden;
  transition: transform 0.1s;
  transition-delay: 0.1s;
}


h2 > span > span{
  top: 0;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

h2 > span.bottom > span{
  top: auto;
  bottom: 0;
  text-align: center;
}


h2::after{
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: white;
  
  transform: translate3d(-150%, -50%,0);
  transition: transform 0.3s;
}

h2:hover::after{
  transform: translate3d(-50%, -50%, 0);
}

h2:hover .top{
  transform: translate3d(5%, 0, 0);
}


h2:hover .bottom{
  transform: translate3d(-5%, 0, 0);
}

.bebas {
  font-family: 'Bebas Neue', cursive;
}

.roboto{
  font-family: 'Roboto', sans-serif;
}

.bit-top-track-button,
.bit-nav-bar-container {
  display: none !important;
}

.bit-event {
  border-bottom: 1px solid black;
}

.bit-event:hover {
  background-color: #ffffff !important;
}

.bit-details {
  align-items: flex-end !important;  
}

.bit-event-buttons {
  padding-bottom: 0 !important;
}

.bit-single-cta {
  background: transparent;
}

.bit-rsvp-container {
  display: none;
}

.bit-play-my-city-button {
  font-size: 24px !important;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  background: #ffffff0a;
}

.bit-startTime,
.bit-venue,
.bit-location,
.bit-offers-text,
.bit-upcoming-events-show-all-button,
.bit-play-my-city-button {
  font-family: 'Bebas Neue', cursive !important;
  color: black !important;
  font-size: 1.2rem !important;
}

.bit-date {
  font-family: 'Bebas Neue', cursive !important;
  color: black !important;
  font-size: 2rem !important;
}

.bit-details > div:first-of-type {
  min-width: 40%;
}

.bit-venue,
.bit-location,
.bit-startTime-container,
.bit-startTime {
  margin-right: 12px;
  margin-left: 12px;
  font-weight: normal !important;
}

.bit-widget .bit-no-dates-container {
  border-top: none !important;
}

.bit-no-dates-title {
  font-family: 'Bebas Neue', cursive !important;
  font-size: 1.8rem !important;
  font-weight: normal !important;
  margin-bottom: 1rem !important;
}

.bit-button {
  color: black !important;
  font-family: 'Bebas Neue', cursive !important;
}

.bit-widget .bit-event .bit-date {
  font-weight: normal !important;
}

.bit-widget-container {
  max-width: 1200px;
  margin: 0 auto;
}

.bit-offers-text {
  font-weight: normal !important;
}

.bit-offers.bit-button,
.bit-play-my-city-button,
.bit-upcoming-events-show-all-button {
  font-family: "Staatliches", cursive;
  font-size: 24px !important;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  background: #ffffff0a !important;
  transition: all 0.2s;
}

.bit-offers.bit-button:hover,
.bit-play-my-city-button:hover,
.bit-upcoming-events-show-all-button:hover {
  transition: all 0.2s;
  background: #cecece !important;
}

.bit-event-buttons {
  padding-bottom: 15px;
}


.hero-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_img_txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12vw;
  line-height: 10vw;
}

.hero_img {
  min-height: 100vh;
  min-width: 100vw;
  width: auto;
  height: auto;
  object-fit: cover;
}

.hero_subtitle {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
  font-family: "Roboto Slab", serif;
}

.logo-about {
  transform: scale(1.6);
  padding: 2rem 2rem 5rem 2rem;
}

.video-container {
  margin: 0.1rem auto;
}

.video {
  width: 70vw;
  height: 40vw;
}

.bandcamp-widget-big {
  border: 0;
  width: 360px;
  height: 560px;
  margin: 1rem;
}

.stickyFooter {
  position: fixed;
  overflow: hidden;
  bottom: 5%;
  z-index: 2;
  height: 200;
}

.social-icon {
  padding: 10px;
}

.social-icon:hover {
  cursor: pointer;
}

.social-icon:hover + .social-icon-name {
  cursor: pointer;
  transition: all 0.2s;
  transform: scale(1.2);
  opacity: 100;
  display: block;
  transition: all 0.2s;
}

.social-icon-name {
  display: none;
  opacity: 0;
  transition: all 0.2s;
  font-family: 'Bebas Neue';
  padding-left: 5px;
}

.social-icon-name:hover {
  display: block;
  opacity: 100;
  transform: scale(1.2);
  transition: all 0.2s;
}

.stickyFooterLarge {
position: fixed;
bottom: 0%;
z-index: 2;
overflow: hidden;
width: 100%;


}

#navbar {
  transition: 300ms;
}

.about_img {
  margin: 1.5rem 3rem 1.5rem 3rem;
  transform: scale(1.2);
  border: 2px solid black;
}

.about-slider-left{
  width: 36rem;
  z-index: 1;
}

.about-slider-right{
  width: 36rem;
  z-index: 0;
}

.blockContent-container p {
  padding: 0 2.5rem 1.5rem 2.5rem;
}

p {
  font-size: 1rem;
}

#contact {
  margin-bottom: 600px;
}

footer {
  height: 335px;
}

@media screen and (max-width: 560px) {
  .hero_img_txt {
    font-size: 20vw;
    line-height: 16vw;
  }
  .video {
    width: fit-content;
  }
  .bandcamp-widget-big {
    width: 250px;
    height: 250px;
  }
  .newsletter-container {
    width: 300px;
  }
  .hero_img_txt {
    font-size: 20vw;
    line-height: 16vw;
  }
  p {
    font-size: 14px;
  }
  .about-slider-left{
    width: 24rem;
  }
  footer {
    height: 500px;
  }
  #contact {
    margin-bottom: 800px;
  }
  .about-slider-right{
    width: 24rem;
  }
  .logo-about{
    transform: scale(1);
    padding: 1rem 2rem 1rem 2rem;
  }
  .bit-details {
    display: inline !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {  
  h2{
    font-size: 5rem;
  }
  
  h2 > span{
    height: 2.5rem;
  }
  .newsletter-background {
    height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .newsletter-img {
    transform-origin: 30%;
    position: relative;
    left: 21%;
    top: 5rem;
    transform: scale(0.6);
  }
  
  .newsletter-slider-bottom {
    margin: 0 auto;
    padding-bottom: 36rem;
  }
  
  .background-clip {
    background: black;
    margin-top: 18rem;
    height: 18rem;
    width: 500%;
    display: flex;
    justify-content: center;
  }
  .bit-details {
    display: inline !important;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  h2{
    font-size: 8rem;
  }
  
  h2 > span{
    height: 4rem;
  }
  .newsletter-background {
    height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .newsletter-img {
    transform-origin: 30%;
    position: relative;
    left: 21%;
    top: 5rem;
    transform: scale(0.8);
  }
  
  .newsletter-slider-bottom {
    margin: 0 auto;
    padding-bottom: 36rem;
    padding-top: 4rem;
  }
  
  .background-clip {
    background: black;
    margin-top: 22rem;
    height: 22rem;
    width: 500%;
    display: flex;
    justify-content: center;
  }
  .bit-details {
    display: inline !important;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h2{
    font-size: 10rem;
  }
  
  h2 > span{
    height: 5rem;
  }
  .bit-details {
    display: inline !important;
  }
}

/* Large devices (laptops/desktops, 1080px and up) */
@media only screen and (min-width: 1080px) {
  h2{
    font-size: 14rem;
  }
  
  h2 > span{
    height: 7rem;
  }
  .newsletter-background {
    height: 24rem;
    display: flex;
    flex-direction: row-reverse;
  }
  .newsletter-img {
    transform-origin: 30%;
    position: relative;
    top: -5rem;
    transform: scale(1);
  }
  
  .newsletter-slider-bottom {
    padding: 4rem 12rem;
  }
  
  .background-clip {
    margin-top: 6rem;
    background: black;
    height: 24rem;
    width: 500%;
    display: flex;
    justify-content: center;
  }
  .bit-details {
    display: flex !important;
  }
}

/* Extra large devices (large laptops and desktops, 1280px and up) */
@media only screen and (min-width: 1280px) {
  h2{
    font-size: 18rem;
  }
  
  h2 > span{
    height: 9rem;
  }
}